<template>
    <div id="app">
        <loadingPage v-if="hide"></loadingPage>
        <router-view v-else/>
        <el-backtop style="color:var(--titleColor)"/>
        <tip/>
    </div>
</template>

<script type="application/javascript">
import tip from '@/components/tip'
import loadingPage from '@/components/loadingPage'

export default {
    components: {loadingPage, tip},
    data() {
        return {hide: true}
    },
    mounted() {
        $(document).ready(function () {isScroll.init('.scroll-animate')});
        this.$store.commit('set_key', {pkcsType: 'pkcs8', pkcsSize: '1024'});
        this.$store.dispatch('getServerKey');
        this.$store.dispatch('setThemeColer');

        $(':root').css({
            "--color": this.$store.state.themeColor.color,
            "--bgColor": this.$store.state.themeColor.bgColor,
            "--bgColor1": this.$store.state.themeColor.bgColor1,
            "--bgColor2": this.$store.state.themeColor.bgColor2,
            "--menuColor": this.$store.state.themeColor.menuColor,
            "--fontColor": this.$store.state.themeColor.fontColor,
            "--titleColor": this.$store.state.themeColor.titleColor,
            "--tableColor": this.$store.state.themeColor.tableColor,
            "--inputBgColor": this.$store.state.themeColor.inputBgColor
        })
        document.title = this.$t('page.loading');
        var that = this
        var timer = setInterval(() => {
            if (document.readyState === 'complete') {
                document.title = that.$t('page.spo');
                that.hide = false
                document.documentElement.scrollTop = 0;
                localStorage.setItem('scrollTop', 0);
                window.clearInterval(timer)
            }
        }, 300)
    },
}
</script>
<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2C3E50;
}

::selection {
    color: #FFFFFF;
    background: var(--titleColor);
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.cv-preloader div {
    box-shadow: 0 5px 0 0 var(--titleColor);
}

.gs-w, .player, .preview-holder {
    transition: all 0.15s ease;
}

* {
    margin: 0;
    padding: 0;
}

img {
    user-select: none
}

i {
    transition: all 0.15s ease
}

span {
    transition: all 0.15s ease;
}

p {
    transition: all 0.15s ease;
}

button:hover > i {
    transform: scale(1.35);
    animation: button_hover 0.6s infinite;
    animation-iteration-count: 1
}

.swing-animation:hover {
    transform: scale(1.35);
    animation: button_hover 0.6s infinite;
    animation-iteration-count: 1
}

button:hover > .iconfont {
    display: inline-block;
    transform: scale(1.35);
    animation: button_hover 0.6s infinite;
    animation-iteration-count: 1
}

button:hover > span > i {
    transform: scale(1.35) translateX(-3px);
    animation: button_span_hover 0.6s infinite;
    animation-iteration-count: 1
}

button:hover > span > {
    transform: scale(1.35) translateX(-3px);
    animation: button_span_hover 0.6s infinite;
    animation-iteration-count: 1
}

@keyframes button_hover {
    0% {
        transform: scale(1) rotate(0deg);
    }
    20% {
        transform: scale(1.15) rotate(35deg);
    }
    40% {
        transform: scale(1.23) rotate(-50deg);
    }
    60% {
        transform: scale(1.35) rotate(25deg);
    }
    80% {
        transform: scale(1.4) rotate(-15deg);
    }
    100% {
        transform: scale(1.35) rotate(0deg);
    }
}

@keyframes button_span_hover {
    0% {
        transform: scale(1) rotate(0deg);
    }
    40% {
        transform: scale(1.13) translateX(-1px) rotate(-25deg);
    }
    60% {
        transform: scale(1.25) translateX(-2px) rotate(25deg);
    }
    80% {
        transform: scale(1.3) translateX(-3px) rotate(-10deg);
    }
    100% {
        transform: scale(1.25) translateX(-3.5px) rotate(0deg);
    }
}

/* body{height:100%}
html{height:100%} */
/* 避免页面四周旁白的问题 */
/*
#app {padding: 0px;}
#nav a {font-weight: bold;color: #2c3e50;}
#nav a.router-link-exact-active {color: #42b983;} */
</style>

